
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {},
  props: [
    "meta",
    "source",
    "telemetry",
    "title",
    "height",
    "data_key",
    "min",
    "max",
    "series_key",
  ],
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    moment,
  }),
  methods: {},
})
export default class Breweries extends Vue {}
