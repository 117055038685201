var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.telemetry &&
    _vm.telemetry.some((batch) =>
      batch.devices.ALICAT[_vm.source || 'telemetry'].some((x) => x[_vm.data_key])
    )
  )?_c('apex-chart',{key:_vm.id,attrs:{"type":"line","height":_vm.height,"options":{
    tooltip: {
      enabled: true,
      shared: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
        const hoverIndexes = w.globals.seriesX.map((seriesX) => {
          return seriesX.findIndex((xData) => xData === hoverXaxis);
        });
        let hoverList = '';
        hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
          if (hoverIndex >= 0) {
            hoverList += `<span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
          }
        });
        return `<div class='card'>
      <div class='card-header p-1'>${hoverXaxis}</div>
      <div class='card-body p-1'>
        ${hoverList}
      </div>
    </div>`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: { text: _vm.title },
    chart: {
      id: 'chart-slpm' + _vm.data_key,
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
        type: 'x',
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'numeric',
      labels: {
        formatter: function (value, timestamp, opts) {
          return Math.round(
            (value - +new Date(1970, 1, 1) / 1000) / (3600 * 1000)
          );
        },
      },
    },
    stroke: {
      width: [3, 3, 2],
      curve: 'straight',
    },
    annotations: {
      xaxis: [],
    },
    yaxis: [
      {
        max: _vm.max != null ? _vm.max : undefined,
        min: _vm.min,
        decimalsInFloat: 3,
        axisBorder: {
          show: true,
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        opposite: false,
        labels: {
          minWidth: 40,
          formatter: function (val) {
            return val.toFixed(3);
          },
        },
      },
    ],
  },"series":[
    ..._vm.telemetry.map((batch) => ({
      name: _vm.series_key(batch),
      type: 'line',
      data: batch.devices.ALICAT[_vm.source || 'telemetry']
        .filter((x) => x[_vm.data_key])
        .map((x) => ({
          x: new Date(
            x.ts - batch.startTime + 3600 * batch.offset * 1000
          ).getTime(),
          y: x[_vm.data_key],
        })),
    })),
  ]}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }