
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import chart from "./chart.vue";
import _ from "lodash";

const deviation = (x: any) => {
  let xBar, n, d, i;
  xBar = _.mean(x);
  n = x.length;
  d = new Array(n);
  for (i = 0; i < n; i++) {
    d[i] = x[i] - xBar;
  }
  return d;
};

const variance = (x: any, bias: any) => {
  let d, i, n;
  bias = typeof bias === "boolean" ? bias : false;
  d = deviation(x);
  n = d.length;
  for (i = 0; i < n; i++) {
    d[i] = Math.pow(d[i], 2);
  }
  return _.sum(d) / (n - (bias === false ? 1 : 0));
};

const standardDeviation = (x: number, bias?: any) => {
  bias = typeof bias === "boolean" ? bias : false;
  return Math.sqrt(variance(x, bias));
};

const standardize = (x: any) => {
  let sd, d, i, n;
  sd = standardDeviation(x);
  d = deviation(x);
  for (i = 0, n = d.length; i < n; i++) {
    d[i] = d[i] / sd;
  }
  return d;
};

const pcorr = (x: number[], y: number[]) => {
  x = standardize(x);
  y = standardize(y);
  let n = x.length;
  let d = new Array(n);
  for (let i = 0; i < n; i++) {
    d[i] = x[i] * y[i];
  }
  return _.sum(d) / (n - 1);
};
const pcorr2 = (x: number[], y: number[]) => {
  let xDeviation = deviation(x);
  let yDeviation = deviation(y);
  return (
    _.sum(
      xDeviation.map(function (xi, i) {
        return xi * yDeviation[i];
      })
    ) /
    Math.sqrt(
      _.sum(
        xDeviation.map(function (xi) {
          return Math.pow(xi, 2);
        })
      ) *
        _.sum(
          yDeviation.map(function (yi) {
            return Math.pow(yi, 2);
          })
        )
    )
  );
};
const offsetCalculation = (input: any[], p: string) => {
  let batches: any[] =
    input.map((batch: any) => {
      let k = batch.devices.ALICAT.telemetry as any[];
      console.warn("series", k);
      let normalized =
        k
          ?.map((x) => ({
            x: (x.ts - batch.startTime) / 1000 / 3600,
            y: x[p],
          }))
          .filter((y: any) => y.y != null) ??
        [].map((a, i, c) => {
          if (i == 0) return 0;
          return a - c[i - 1];
        });

      return {
        name: batch.name,
        startTime: batch.startTime,
        normalized: normalized.slice(1, normalized.length - 1),
        sum: 0,
      };
    }) ?? [];
  for (let index = 1; index < batches.length; index++) {
    batches[index].sum = [];
    let length = Math.min(
      batches[0].normalized.length - 1,
      batches[index].normalized.length - 1
    );
    let max = 20;
    length -= max;
    for (let offset = 0; offset < max; offset += 1) {
      let x = _.map(batches[0].normalized.slice(0, length), "y");
      let y = _.map(batches[index].normalized.slice(0, length), "y");
      console.log("cov", p, batches[index].name, { x, y });
      batches[index].sum.push({
        offset,
        hours: offset / 2,
        minus: pcorr(x.slice(0, length - offset), y.slice(offset, length)),
        plus: pcorr(x.slice(offset, length), y.slice(0, length - offset)),
      });
    }
  }
  return batches;
};
@Component({
  components: { chart },
  props: ["meta", "telemetry", "offset_hours", "series_key"],
  computed: {
    show_key() {
      if ((this as any).series_key == "device_name") {
        return (x: any) => x.devices.ALICAT.name;
      }
      return (x: any) => x.name;
    },
    id() {
      return this.$route.params.id;
    },
  },
  data: () => ({
    height: 300,
    offsets: [],
    moment,
  }),
  methods: {
    autoOffset(key) {
      this.$data.offsets = (this as any).calculate_offsets();
      for (const batch of (this as any).telemetry) {
        let x =
          _.find((this as any).offsets, (x) => x.name == batch.name)?.[key] ??
          0;
        console.log("ssss", x);
        Vue.set(batch, "offset", x);
      }
    },
    offset(batch, value) {
      console.log("offset", batch, value);
      Vue.set(batch, "offset", value);
    },
    calculate_offsets() {
      let mass_flow = offsetCalculation(
        (this as any).telemetry,
        "flowrate_avg"
      );
      let abv = offsetCalculation((this as any).telemetry, "ABV");
      let sg = offsetCalculation((this as any).telemetry, "SG");
      const convert = (input: any[], key: string) =>
        input.map((x: any) => {
          let m: any = _.maxBy(x.sum, "minus");
          let p: any = _.maxBy(x.sum, "plus");
          console.log("ss", { m, p });
          return {
            name: x.name,
            sum: x.sum,
            [key]: m?.minus > p?.plus ? 0 - m?.hours : p?.hours,
          };
        });
      let r = Object.values(
        _.groupBy(
          [
            ...convert(abv, "abv"),
            ...convert(sg, "sg"),
            ...convert(mass_flow, "mass_flow"),
          ],
          "name"
        )
      ).map((x: any) => Object.assign({}, ...x));

      return r;
    },
  },
})
export default class Breweries extends Vue {}
