
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
@Component({
  components: {},
  computed: {},
  props: ["batch"],
  mounted() {
    return;
  },
  data: () => ({
    moment,
  }),
  methods: {},
})
export default class BatchTimeline extends Vue {}
